<template>
  <div>
    <div>
      <div v-if="metadataComputed.description">Description: {{ metadataComputed.description }}</div>
      <div v-if="metadataComputed.source">Source: {{ metadataComputed.source }}</div>
      <div v-if="metadataComputed.comments">Comments: {{ metadataComputed.comments }}</div>
      <div v-if="metadataComputed.tags.length">
        Tags:
        <v-chip
          v-for="tag in metadataComputed.tags"
          :key="tag"
          color="cyan lighten-4"
          label
          small
          class="mr-1"
        >
          {{ tag }}
        </v-chip>
      </div>
      <div class="d-sm-flex d-block justify-space-between">
        <div v-if="metadataComputed.createdAt">
          <strong>Added:</strong> <span>{{ metadataComputed.createdAt }}</span>
        </div>
        <div v-if="metadataComputed.updatedAt">
          <strong>Updated:</strong> <span>{{ metadataComputed.updatedAt }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    metadataComputed() {
      const meta = {};

      const m = this.data?.metaData || {};
      meta.description = m.description || '';
      meta.comments = m.comments || '';
      meta.source = m.source || '';
      meta.tags = [];

      const tags = m.tags;
      if (tags && Array.isArray(tags)) {
        tags.forEach(tag => {
          if (typeof tag === 'string') {
            meta.tags.push(tag);
          } else if (typeof tag === 'object') {
            meta.tags.push(tag.text);
          }
        });
      }

      const data = this.data || {};

      if (data.createdAt) {
        const date = data.createdAt?.toDate();
        if (date) {
          meta.createdAt = Moment(date).format('YYYY-MM-DD');
        }
      }

      if (data.updatedAt) {
        const date = data.updatedAt?.toDate();
        if (date) {
          meta.updatedAt = Moment(date).format('YYYY-MM-DD');
        }
      }
      return meta;
    }
  }
};
</script>

<style>
</style>
